"use client";

import classNames from "classnames";
import { NSImage } from "@/components/common/NSImage";
import { NSEmbed } from "@/components/common/NSEmbed";

import { PortableText } from "@portabletext/react";
import { serializers } from "@/utils/sanity";

import { InternalExternalLink } from "@/components/common/InternalExternalLink";

import * as styles from "./PromoCard.module.scss";

const PromoCard = ({
  cardType,
  url,
  image,
  isPortrait,
  background,
  darkMode,
  cardLabel,
  video,
  textArea,
}) => {
  const cls = classNames(
    { [styles.promoCard]: true },
    {
      [styles.backgroundPattern]: background,
    },
    // {
    //   [styles.centeredLabel]: centeredLabel,
    // },
    {
      [styles.darkMode]: darkMode,
    },
  );

  const checkIfNotAlt = (cardType) => {
    if (cardType && cardType.cardTypeName !== "Alt") {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className={cls}>
      {checkIfNotAlt(cardType) && (
        <div className={classNames(styles.cardType, "label")}>
          {cardType && cardType.cardTypeName}
        </div>
      )}
      <div>
        {image && (
          <InternalExternalLink to={url}>
            <NSImage
              image={image.image}
              alt={image.altText || cardType.cardTypeName}
            />
          </InternalExternalLink>
        )}
        {video && (
          <InternalExternalLink to={url}>
            <NSEmbed mediaUrl={video} />
          </InternalExternalLink>
        )}
      </div>
      <div className={classNames(styles.cardLabel, "label")}>
        {cardLabel && cardLabel.cardLabelName}
      </div>
      <div>
        <InternalExternalLink to={url}>
          {textArea && (
            <PortableText value={textArea} components={serializers} />
          )}
        </InternalExternalLink>
      </div>
    </div>
  );
};

export { PromoCard };
