"use client";

import React from "react";
// import Mailchimp from "react-mailchimp-form";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import classNames from "classnames";

import * as styles from "./MailingList.module.scss";

/********
// NOTE: THE DIST VERISON OF 'React-Mailchimp-form' has been hacked to set the id for the Email input field
*********/
const MailingList = ({ title, className }) => {
  return (
    <div className={classNames(styles.mailingList, className)}>
      <div id="mailform-container" className={styles.mailformContainer}>
        <label
          htmlFor="EMAIL"
          aria-label="Enter Email Address"
          form="ns-signup"
        >
          {title}
        </label>
        <div className={styles.mailForm}>
          <MailchimpSubscribe url={process.env.NEXT_PUBLIC_MAILCHIMP_URL} />
        </div>
        {/* <MailchimpSubscribe
          className={styles.mailForm}
          url={process.env.NEXT_PUBLIC_MAILCHIMP_URL}
        /> */}
        {/* <Mailchimp
          className={`${styles.mailForm}`}
          action="https://noshowspace.us4.list-manage.com/subscribe/post?u=2c23c941db491981cd2581c2c&amp;id=a56e753c00"
          fields={[
            {
              name: "EMAIL",
              id: "EMAIL",
              placeholder: "Email",
              type: "email",
              required: true,
            },
          ]}
          // Change predetermined language
          messages={{
            sending: "Sending...",
            success: "Thank you for subscribing!",
            error: "An unexpected internal error has occurred.",
            empty: "You must write an e-mail.",
            duplicate: "Too many subscribe attempts for this email address",
            button: "SIGN UP",
          }}
          id="ns-signup"
        /> */}
      </div>
    </div>
  );
};

export { MailingList };
