import React from "react";

const serializers = {
  types: {
    download: (props) => {
      return (
        <div className="ns-download">
          <p>
            <a
              href={`${props.value?.downloadFile?.asset.url}`}
              target="_blank"
              rel="noreferrer"
              style={{ fontWeight: "bold", textDecoration: "underline" }}
            >
              {props.value?.title}
            </a>
          </p>
        </div>
      );
    },
  },
};

export { serializers };
