import Link from "next/link";
import classNames from "classnames";
import Obfuscate from "react-obfuscate";

const InternalExternalLink = ({
  to,
  label,
  className,
  children,
  direction,
  ...rest
}) => {
  const cls = classNames("link", className);

  if (!to) {
    return <>{children}</>;
  }
  if (to?.startsWith("http")) {
    return (
      <a className={cls} href={to} target="_blank" rel="noreferrer noopener">
        {children}
      </a>
    );
  }
  if (label === "Email") {
    return (
      <Obfuscate className={cls} email={to}>
        {label}
      </Obfuscate>
    );
  }

  return (
    <Link className={cls} href={to}>
      {children}
    </Link>
  );
};

export { InternalExternalLink };
