import classNames from "classnames";
// import { GatsbyImage } from "gatsby-plugin-image";
import Image from "next/image";

import * as styles from "./NSImage.module.scss";

const NSImage = ({ image, alt, className, sizes }) => {
  const percentageRatio =
    (1 / image?.asset?.metadata?.dimensions?.aspectRatio) * 100;

  let hiddenStyle = {
    width: "100%",
    paddingBottom: `${percentageRatio}%`,
  };

  return image?.asset?.extension === "gif" ? (
    <div className={styles.gifWrapper}>
      {/* <div aria-hidden={true} style={hiddenStyle}></div> */}
      <img src={image?.asset?.url} alt={alt} />
    </div>
  ) : (
    <div className={classNames(className, styles.imageWrapper)}>
      <Image
        src={image?.asset?.url}
        width={image?.asset?.metadata?.dimensions?.width}
        height={image?.asset?.metadata?.dimensions?.height}
        alt={alt}
        sizes={sizes}
        blurDataURL={image?.asset?.metadata?.lqip}
        placeholder={image?.asset?.metadata?.lqip ? "blur" : "empty"}
      />
    </div>
  );
};

export { NSImage };
